<template>
  <div id="app">
    <AppHeader />
    <AppCarousel />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppCarousel from "@/components/AppCarousel.vue";
import AppFooter from "@/components/AppFooter.vue";
import { mapActions } from 'vuex';

export default {
  components: {
    AppFooter,
    AppCarousel,
    AppHeader
  },
  created() {
    // 调用 Vuex 的 action 获取图片
    this.$store.dispatch('fetchImages');

    // 调用 Vuex 的 action 预加载视频
    this.preloadVideos();
  },
  methods: {
    ...mapActions(['preloadVideos'])
  }
};
</script>

<style>
#app {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
  overflow: visible;
}
</style>
