<template>
  <div class="footer">
    <span class="site-info">
      © 宁波璟达科技科技有限公司 版权所有 网站备案号：
      <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2025158872号</a>
    </span>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
  },
}
</script>

<style scoped>
.footer {
  width: 100%;
  text-align: center;
  background-color: white;
  height: 5vh; /* 设置高度为5vh */
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

.site-info {
  font-size: 14px;
  color: #666;
  padding: 0 10px; /* 保持一些水平内边距，以防止内容在移动设备上过于靠近边缘 */
}

.site-info a {
  color: #007BFF;
  text-decoration: none;
}

.site-info a:hover {
  text-decoration: none;
}

.site-info a:focus {
  text-decoration: none;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .site-info {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .site-info {
    font-size: 10px;
  }

  .site-info a {
    display: inline-block;
    word-break: break-all; /* 确保长链接在小屏幕设备上换行 */
  }
}
</style>
