import { createStore } from 'vuex';
import api from '@/api.js'; // 引入api.js

export default createStore({
  state: {
    images: [],
    loading: true,
    preloadedVideos: [] // 添加预加载视频的状态
  },
  mutations: {
    setImages(state, images) {
      state.images = images;
      state.loading = false;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setPreloadedVideos(state, videos) {
      state.preloadedVideos = videos;
    }
  },
  actions: {
    async fetchImages({ commit }) {
      try {
        const response = await api.getBanners();
        const baseUrl = process.env.VUE_APP_MEDIA_URL;
        const images = response.data.msg.map(item => {
          return {
            ...item,
            fullImageUrl: baseUrl + item.image
          };
        });
        commit('setImages', images);
      } catch (error) {
        console.error('Error fetching images:', error);
        commit('setLoading', false);
      }
    },
    preloadVideos({ commit }) {
      const videoUrls = [
        "https://aidamedia.oss-cn-hangzhou.aliyuncs.com/01.mp4",
        "https://aidamedia.oss-cn-hangzhou.aliyuncs.com/02.mp4",
        "https://aidamedia.oss-cn-hangzhou.aliyuncs.com/03.mp4",
        "https://aidamedia.oss-cn-hangzhou.aliyuncs.com/04.mp4",
        "https://aidamedia.oss-cn-hangzhou.aliyuncs.com/05.mp4",
        "https://aidamedia.oss-cn-hangzhou.aliyuncs.com/06.mp4",
        "https://aidamedia.oss-cn-hangzhou.aliyuncs.com/07.mp4"
      ];

      const preloadedVideos = videoUrls.map(src => {
        const video = document.createElement('video');
        video.src = src;
        video.preload = 'auto';
        video.load();
        return video;
      });

      commit('setPreloadedVideos', preloadedVideos);
    }
  },
  getters: {
    images: state => state.images,
    loading: state => state.loading,
    preloadedVideos: state => state.preloadedVideos
  }
});
